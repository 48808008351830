import axios from 'axios';

export const sender = async data => {
  try {
    const BOT_TOKEN = '1815433052:AAGw0rJGV0hN3Yb_3_RuK-JKnbpUnrmsYy0';
    const CHAT_ID = '1107954447';

    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const formattedDate = new Date().toLocaleDateString('tr-TR', options);
    const sendingdata = `
📌 Osman Demirci Kişisel Web Sitesi İletişim Formu
📅 Tarih: ${formattedDate}
🔵 Ad: ${data.name}
📧 E-posta: ${data.email}
📝 Mesaj: ${data.message}
📞 Telefon: ${data.phone}
`;

    const response = await axios.post(
      `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage?chat_id=${CHAT_ID}&parse_mode=html&text=${encodeURIComponent(
        sendingdata,
      )}`,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export default sender;
