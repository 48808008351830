import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig, email } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';
import { sender } from '@utils/sender';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }

	.contact {
		display: block;
		background-color: transparent;
		padding: 15px 20px;
		border-radius: 5px;
		box-shadow: 0 10px 30px -15px var(--navy-shadow);
		border: unset !important;
		margin-top: 20px;
		width: 100%;
		transition: var(--transition);
		color: var(--lightest-slate);
		&:focus,
		&:hover {
			background-color: var(--hover);
			box-shadow: 0 10px 30px -15px var(--navy-shadow);
		}

		&::placeholder {
			color: var(--green);
		}

		&:focus {
			outline: none;
			border-color: var(--green);
			box-shadow: 0 10px 30px -5px var(--pink);
		}
	}

	.resultmessage {
		color: var(--green);
		font-size: var(--fz-lg);
		margin-top: 20px;,
		transition: var(--transition);
		padding: 30px;
		border-radius: 5px;
		background-color: var(--hover);
		box-shadow: 0 10px 30px -15px var(--navy-shadow);
		&.hidden {
			display: none;
		}
		&.warn {
			box-shadow: 0 10px 30px -15px #8b0000 !important;
			border: 1px solid #8b0000 !important;
			color: #8b0000 !important;
		}
	}
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    // eğer contact-form id'li form varsa
    const form = document.getElementById('contact-form');
    const result = document.querySelector('.resultmessage');
    if (form) {
      form.addEventListener('submit', e => {
        e.preventDefault();
        const name = document.querySelector('.contact.name').value;
        const email = document.querySelector('.contact.email').value;
        const phone = document.querySelector('.contact.phone').value;
        const message = document.querySelector('.contact.message').value;

        if (!name || !email || !phone || !message) {
          result.classList.remove('hidden');
          result.classList.add('warn');
          result.innerHTML = 'Lütfen tüm alanları doldurunuz.';
          if (!name) {
            document.querySelector('.contact.name').focus();
            return;
          }
          if (!email) {
            document.querySelector('.contact.email').focus();
            return;
          }
          if (!phone) {
            document.querySelector('.contact.phone').focus();
            return;
          }
          if (!message) {
            document.querySelector('.contact.message').focus();
            return;
          }
          return;
        }

        const data = { name, email, phone, message };
        const resp = sender(data);
        if (resp) {
          document.querySelector('.contact.name').value = '';
          document.querySelector('.contact.email').value = '';
          document.querySelector('.contact.phone').value = '';
          document.querySelector('.contact.message').value = '';
          form.hidden = true;
          result.classList.remove('hidden');
          result.classList.remove('warn');
          result.innerHTML = 'Mesajınız başarıyla gönderildi. En kısa sürede size dönüş yapacağım.';
        }
      });
    }

    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading overline">İletişim</h2>

      <h2 className="title">Bana Ulaşmak ister misin?</h2>

      <p>
        Şu anda yeni fırsatlar arıyorum ve gelen kutum her zaman açık. Bir sorunuz olsun ya da
        sadece merhaba demek isteyin, size geri dönmek için elimden geleni yapacağım!
      </p>

      <br />
      <form id="contact-form" method="post">
        <input type="text" placeholder="Adınız" className="contact name" />
        <input type="email" placeholder="E-posta adresiniz" className="contact email" />
        <input type="text" placeholder="Telefon numaranız" className="contact phone" />
        <textarea placeholder="Mesajınız" className="contact message" rows="5" />
        <button type="submit" className="email-link">
          Gönder
        </button>
      </form>
      <div className="resultmessage hidden"></div>

      <div className="mt-10">
        <a href={`mailto:${email}`} className="email-link">
          Bana E-posta Gönder
        </a>
      </div>
    </StyledContactSection>
  );
};

export default Contact;
